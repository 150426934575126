@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Manrope:wght@200;300;400;500;600;700;800&display=swap");


.ThinScrollbar::-webkit-scrollbar {
  width: 5px;
  height: 1px;
}
.ThinScrollbar::-webkit-scrollbar-track {
  position: absolute;
  box-shadow: inset 0 0 5px #e1e1f4;
  border-radius: 10px;
}

/* Handle */
.ThinScrollbar::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
  color: gray;
}

.hideScrollBar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
